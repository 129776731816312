<template>
  <div class="wrap">
    <template v-if="!topMsgShow">
      <NavBar
        :title="roomInfo.username ? String(roomInfo.username) : ''"
        left-arrow
        @click-left="$router.go(-1)"
      />
      <NoticeBar
        color="#1989fa"
        background="#ecf9ff"
        left-icon="volume-o"
        v-if="topMsgInfo.content"
        @click="topMsgShow = true"
      >
        <!-- <div v-html="topMsgInfo.content"></div> -->
        {{ topMsgInfo.contentText }}
      </NoticeBar>
      <div class="loading_wrap" v-if="loading">
        <Loading size="20" />
      </div>
      <div
        class="list"
        ref="list"
        @scroll="next"
        :style="{
          height: !topMsgInfo.content
            ? 'calc(100vh - 46px - 85px)'
            : 'calc(100vh - 46px - 85px - 40px)',
        }"
      >
        <div
          class="list_item"
          v-for="(item, index) in messages"
          :key="item.id || `message_${index}`"
          :style="{
            marginTop:
              !index || (!!index && item.userid !== messages[index - 1].userid)
                ? ''
                : '36px',
          }"
        >
          <div
            class="time"
            v-if="
              !index || (!!index && item.userid !== messages[index - 1].userid)
            "
          >
            {{ $format(item.indate) }}
          </div>
          <div class="content_bar" v-if="item.userid != userInfo.id">
            <img :src="item.userimg" alt="" class="avatar" />
            <div class="right_bar">
              <div
                class="nickname"
                v-if="
                  !index ||
                  (!!index && item.userid !== messages[index - 1].userid)
                "
              >
                {{ item.username }}:
              </div>
              <div class="msg_wrap">
                <!-- <div class="left"></div> -->
                <div
                  class="msg"
                  :class="`${
                    !index ||
                    (!!index && item.userid !== messages[index - 1].userid)
                      ? 'top16'
                      : ''
                  } ${item.type == 'image' ? 'msg_image' : ''}`"
                >
                  <img
                    :src="$img(item.content)"
                    alt=""
                    v-if="item.type == 'image'"
                    class="images"
                    @click="preview(item.content)"
                  />
                  <span v-else v-html="item.content"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="content_bar ver_end" v-else>
            <div class="left_bar">
              <div class="msg_wrap">
                <!-- <div class="left"></div> -->
                <div
                  class="msg"
                  :class="item.type == 'image' ? 'msg_image' : ''"
                >
                  <img
                    :src="$img(item.content)"
                    alt=""
                    v-if="item.type == 'image'"
                    class="images"
                    @click="preview(item.content)"
                  />
                  <div v-else v-html="item.content"></div>
                </div>
              </div>
            </div>
            <img :src="userInfo.userimg" alt="" class="avatar" />
          </div>
        </div>
        <div class="placeholder"></div>
      </div>
      <div class="footer">
        <!-- <input type="text" v-model="msgInput" placeholder="Nhập tin nhắn..." /> -->
        <textarea
          v-model="msgInput"
          placeholder="Nhập tin nhắn..."
          @keyup.enter="send()"
        ></textarea>
        <div class="photo_wrap">
          <input type="file" class="file_input" @input="upload" />
          <Icon name="photo-o" class="photo" />
        </div>
        <button class="send" @click="send()">Gửi</button>
      </div>
    </template>
    <TopMsgDetails :data="topMsgInfo" v-model="topMsgShow" />
  </div>
</template>

<script>
import { $get, $post, $upload } from '@/utils/request.js'
import TopMsgDetails from './topMsgDetails.vue'
import {
  NavBar,
  PullRefresh,
  List,
  Icon,
  Toast,
  ImagePreview,
  Loading,
  NoticeBar
} from 'vant'
import TimeCheck from '@/utils/timeSynchronization.js'
export default {
  components: {
    NavBar,
    PullRefresh,
    List,
    Icon,
    Loading,
    NoticeBar,
    TopMsgDetails
  },
  data () {
    return {
      topMsgShow: false,
      roomInfo: {},
      messages: [],
      interval: null,
      loading: false,
      userInfo: {},
      msgInput: '',
      sendLoading: false,
      page: 1,
      hasMore: true,
      newIndex: '',
      oldIndex: '',
      getMsgLoading: false,
      topMsgInfo: {},
      tempMsg: {}
    }
  },
  created () {
    this.roomInfo.id = this.$route.query.id
    this.getRoomInfo()
    this.getMessages()
    this.getTopmessage()
    !this.interval && (this.interval = new TimeCheck({ time: new Date() }))
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
  },
  methods: {
    handleKeyUp (e) {
      
    },
    async getRoomInfo () {
      try {
        const {
          data: { ret, msg, data }
        } = await $get('/rooms/add', { id: this.roomInfo.id })
        if (ret == 1) {
          // const { hasMorePages, lists } = data;
          this.roomInfo = data
        }
      } catch (error) {
        
      }
    },
    setStorage (index) {
      let storage = localStorage.getItem('seen')
      if (storage) {
        storage = JSON.parse(storage)
      } else {
        storage = {}
      }
      storage[this.roomInfo.id] = index
      localStorage.setItem('seen', JSON.stringify(storage))
    },
    toListBottom () {
      this.$nextTick(() => {
        const height = this.$refs.list.scrollHeight
        this.$refs.list.scrollTo(0, height)
      })
    },
    async getMessages (params = {}) {
      this.getMsgLoading = true
      try {
        const res = await $get('/rooms/comment', {
          id: this.roomInfo.id,
          ...params
        })
        const { ret, msg, data } = res.data
        this.getMsgLoading = false
        this.loading = false
        if (ret == 1) {
          const { hasMorePages, lists } = data
          // ;
          if (!params.page && !params.index) {
            this.hasMore = hasMorePages
            if (lists[0]) {
              this.newIndex = lists[0].index
              this.oldIndex = lists[0].index
            }
            this.setStorage(this.newIndex)
            this.messages = lists.reverse()
            this.toListBottom()
            this.interval.sleep(() => {
              if (!this.getMsgLoading) {
                this.getMessages({ index: this.newIndex })
              }
            }, 1)
            return
          }
          if (params.page) {
            this.hasMore = hasMorePages
            const oldHeight = this.$refs.list.scrollHeight
            this.messages = [...lists.reverse(), ...this.messages]
            this.$nextTick(() => {
              const newHeight = this.$refs.list.scrollHeight
              this.$refs.list.scrollTo(0, newHeight - oldHeight)
            })
            return
          }
          if (params.index) {
            if (!lists.length) return
            this.newIndex = lists[0].index
            this.setStorage(this.newIndex)
            if (lists[0].id == this.tempMsg.id) return
            this.messages = [...this.messages, ...lists.reverse()]
            this.toListBottom()
          }
        }
      } catch (error) {
        
        this.loading = false
        this.getMsgLoading = false
      }
    },
    next () {
      const top = this.$refs.list.scrollTop
      if (top == 0 && this.hasMore && this.loading == false) {
        this.loading = true
        this.page++
        this.getMessages({
          page: this.page,
          index: this.oldIndex
        })
      }
    },
    async send (file = '') {
      if (this.sendLoading) return
      Toast.loading({ duration: 0 })
      this.sendLoading = true
      try {
        const res = await $post('/rooms/comment/add', {
          id: this.roomInfo.id,
          content: file || this.msgInput,
          type: file ? 'image' : 'text'
        })
        const { ret, msg, data } = res.data
        Toast.clear()
        this.sendLoading = false
        this.msgInput = ''
        if (ret == 1) {
          const { comment_new } = data
          this.tempMsg = comment_new[0]
          this.messages.push(comment_new[0])
          this.$nextTick(() => {
            const height = this.$refs.list.scrollHeight
            this.$refs.list.scrollTo(0, height)
          })
          return
        }
        Toast(msg)
      } catch (error) {
        
        Toast.clear()
        this.sendLoading = false
      }
    },
    async upload (e) {
      Toast.loading({ duration: 0 })
      const file = e.target.files[0]
      const params = new FormData()
      params.append('image', file)
      try {
        const { data: res } = await $upload({
          url: '/users/upload',
          data: params
        })
        const { ret, data, msg } = res
        this.send(data.path)
      } catch (error) {
        
        Toast.clear()
      }
    },
    preview (url) {
      ImagePreview({ images: [this.$img(url)], closeable: true })
    },
    async getTopmessage () {
      try {
        const res = await $get('/find/comment', {
          id: this.roomInfo.id,
          istop: 1
        })
        const { ret, msg, data } = res.data
        if (ret == 1) {
          const { hasMorePages, lists } = data
          this.topMsgInfo = lists[0] || {}
          const msgDiv = document.createElement('div')
          msgDiv.innerHTML = this.topMsgInfo.content || ''
          this.topMsgInfo.contentText = msgDiv.innerText
        }
      } catch (error) {
        
      }
    }
  },
  destroyed () {
    this.interval.clear()
  }
}
</script>

<style lang="less" scoped>
.wrap {
  height: 100vh;
  background: #f4f5f8;
  overflow: hidden;
  font-size: 32px;
}

.loading_wrap {
  display: flex;
  justify-content: center;
  padding-top: 12px;
  padding-bottom: 8px;
}

.list {
  .placeholder {
    height: 40px;
  }

  padding: 0 32px 0;
  // margin-bottom: 800px;
  padding-top: 25px;
  height: calc(100vh - 46px - 195px - 40px);
  // height: calc(100vh - 86px);
  overflow-y: auto;

  .list_item {
    margin-top: 56px;

    .time {
      font-size: 20px;
      line-height: 20px;
      color: #010101;
      text-align: center;
      margin-bottom: 26px;
    }

    .ver_end {
      // align-items: flex-end;
    }

    .content_bar {
      display: flex;

      .avatar {
        width: 90px;
        height: 90px;
        border-radius: 8px;
      }

      .top16 {
        margin-top: 16px;
      }

      .right_bar {
        margin-left: 20px;

        .nickname {
          font-size: 24px;
          color: #8e8e8e;
          margin-left: 15px;
        }

        .msg_wrap {
          position: relative;

          .left {
            // position: absolute;
            // width: 44px;
            // height: 30px;
            // left: -20px;
            // top: 0;
            // background-image: url("../../assets/icon/chatroom/bubble.png");
            // background-position: left top;
          }

          .msg {
            // background-image: url("../../assets/icon/chatroom/bubble.png");
            background: #fff;
            border-radius: 20px;
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
            padding: 34px;
            padding-left: 37px;
            background-position: top left;
            background-size: 100% 100%;
            min-width: 276px;
            max-width: 386px;
            word-break: break-word;

            .images {
              max-width: 386px;
              width: 200px;
              min-height: 40px;
            }
          }

          .msg_image {
            display: flex;
            background: none;
            padding: 0;
            padding-left: 10px;
          }
        }
      }

      .left_bar {
        .right_bar();
        justify-content: flex-end;
        width: 100%;
        margin-right: 24px;
        margin-left: 0;

        .msg_wrap {
          display: flex;
          justify-content: flex-end;

          .msg {
            margin-top: 0;
            // background-image: url("../../assets/icon/chatroom/bubble_mine.png");
            box-shadow: none;
            background: linear-gradient(to left, #9275e7, #747fe6);
            color: #fff;
            padding: 25px;
            padding-left: 25px;
            padding-right: 37px;
          }

          .msg_image {
            background: none;
            padding: 0;
            padding-right: 10px;
            justify-content: flex-end;
          }
        }
      }
    }
  }
}

.footer {
  padding: 20px 30px 30px;
  background: #f4f5f8;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: calc(100vw - 60px);

  > input {
    flex-grow: 1;
    padding: 20px;
    border-radius: 10px;
    border: 4px solid #6385e6;
  }

  > textarea {
    flex-grow: 1;
    padding: 20px;
    height: 36px;
    border-radius: 10px;
    border: 4px solid #6385e6;
    font-size: 32px;
  }

  .editor {
    flex-grow: 1;
    // height: 600px;
    padding: 20px;
    border-radius: 10px;
    border: 4px solid #6385e6;

    /deep/ .w-e-scroll {
      border: none !important;

      div {
        border: none !important;
      }
    }

    /deep/ #wangEditor-1 {
      border: none !important;
    }
  }

  /deep/ .tox-tinymce {
    flex-grow: 1;
    padding: 20px;
    border-radius: 10px;
    border: 4px solid #6385e6;
    height: 60px;

    #tinymce {
      margin: 0 !important;
    }
  }

  .photo_wrap {
    position: relative;

    .file_input {
      margin-left: 25px;
      width: 60px;
      height: 60px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 9;
      opacity: 0;
    }

    .photo {
      margin-left: 25px;
      font-size: 60px;
      color: #a4afcf;
    }
  }

  .send {
    margin-left: 25px;
    color: #fff;
    background: #847ae7;
    border: none;
    padding: 12px 20px;
    border-radius: 8px;
    font-size: 32px;

    &:active {
      opacity: 0.8;
    }
  }
}
</style>
